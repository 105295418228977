import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import curriculum from "../assets/curriculum.png";
import Events from "../assets/curriculumEvents.png";
import EventsM from "../assets/curriculumEventsMobile.png";
import BG from "../assets/s2bg.png";

class Curriculum extends React.Component {
  render() {
    return (
      <>
        <Animator
          animation={batch(
            Fade(),
            ZoomIn(1.5, 1),
            Sticky(50, 100 - (686 / 2 / 812) * 100)
          )}
        >
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "bottom center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            {
              in: {
                style: {
                  transform: (i) =>
                    i > 0.8
                      ? ""
                      : `translate(${300 * Math.pow(1 - i / 0.8, 2)}px, ${
                          -200 * (1 - i / 0.8)
                        }px)`,
                },
              },
            },
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 242 / 2 / 375) * 100
                : (80 / this.props.width + 302 / 2 / 1440) * 100,
              this.props.width < 900
                ? ((125 + 45 / 2) / 812) * 100
                : ((484 + 56 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (242 / 375) * this.props.width
                  : (302 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (45 / 812) * this.props.height
                  : (56 / 1024) * this.props.height,
              backgroundImage: `url("${curriculum}")`,
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            {
              in: {
                style: {
                  transform: (i) =>
                    i > 0.8
                      ? ""
                      : `translate(${300 * Math.pow(1 - i / 0.8, 2)}px, ${
                          -200 * (1 - i / 0.8)
                        }px)`,
                },
              },
            },
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 308 / 2 / 375) * 100
                : 50 + (156 / 2 / 1440) * 100,
              this.props.width < 900 ? ((194 + 538 / 2) / 812) * 100 : 50
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? Math.min(
                      (308 / 375) * this.props.width,
                      (308 / 812) * this.props.height
                    )
                  : Math.min(
                      (552 / 1440) * this.props.width,
                      (552 / 1024) * this.props.height
                    ),
              height:
                this.props.width < 900
                  ? Math.min(
                      (590 / 375) * this.props.width,
                      (590 / 812) * this.props.height
                    )
                  : Math.min(
                      (767 / 1440) * this.props.width,
                      (767 / 1024) * this.props.height
                    ),
              backgroundImage:
                this.props.width < 900
                  ? `url("${EventsM}")`
                  : `url("${Events}")`,
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
            }}
          >
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left:
                  this.props.width < 900
                    ? Math.min(
                        (83 / 375) * this.props.width,
                        (83 / 812) * this.props.height
                      )
                    : Math.min(
                        (394 / 1440) * this.props.width,
                        (394 / 1024) * this.props.height
                      ),
                top:
                  this.props.width < 900
                    ? Math.min(
                        (56 / 375) * this.props.width,
                        (56 / 812) * this.props.height
                      )
                    : Math.min(
                        (45 / 1440) * this.props.width,
                        (45 / 1024) * this.props.height
                      ),
                width:
                  this.props.width < 900
                    ? Math.min(
                        (82 / 375) * this.props.width,
                        (82 / 812) * this.props.height
                      )
                    : Math.min(
                        (117 / 1440) * this.props.width,
                        (117 / 1024) * this.props.height
                      ),
                height:
                  this.props.width < 900
                    ? Math.min(
                        (22 / 375) * this.props.width,
                        (22 / 812) * this.props.height
                      )
                    : Math.min(
                        (32 / 1440) * this.props.width,
                        (32 / 1024) * this.props.height
                      ),
                "--dash": "#DCDCDC",
              }}
              onClick={() =>
                window.open(
                  "https://www.eventbrite.com/e/bridge-the-gap-release-party-registration-321474558057"
                )
              }
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left:
                  this.props.width < 900
                    ? Math.min(
                        (83 / 375) * this.props.width,
                        (83 / 812) * this.props.height
                      )
                    : Math.min(
                        (435 / 1440) * this.props.width,
                        (435 / 1024) * this.props.height
                      ),
                top:
                  this.props.width < 900
                    ? Math.min(
                        (112 / 375) * this.props.width,
                        (112 / 812) * this.props.height
                      )
                    : Math.min(
                        (89 / 1440) * this.props.width,
                        (89 / 1024) * this.props.height
                      ),
                width:
                  this.props.width < 900
                    ? Math.min(
                        (82 / 375) * this.props.width,
                        (82 / 812) * this.props.height
                      )
                    : Math.min(
                        (117 / 1440) * this.props.width,
                        (117 / 1024) * this.props.height
                      ),
                height:
                  this.props.width < 900
                    ? Math.min(
                        (22 / 375) * this.props.width,
                        (22 / 812) * this.props.height
                      )
                    : Math.min(
                        (32 / 1440) * this.props.width,
                        (32 / 1024) * this.props.height
                      ),
                "--dash": "#DCDCDC",
              }}
              onClick={() =>
                window.open(
                  "https://rsvp.neuehouse.com/infocusbridgethegap"
                )
              }
            ></div>
          </div>
        </Animator>
      </>
    );
  }
}

Curriculum.propTypes = {
  width: PropTypes.number,
};

Curriculum.defaultProps = {
  width: 1440,
};

export default Curriculum;
