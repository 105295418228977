import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import BTG from "../assets/BTG.png";
import BTGM from "../assets/BTGMobile.png";
import BTGD from "../assets/BTGdesc.png";
import BTGDM from "../assets/BTGdescMobile.png";
import BG from "../assets/s1bg.png";
import clickCursor from "../assets/click.png";

class Welcome extends React.Component {
  render() {
    return (
      <>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(200),
            Sticky(50, 100 - (396 / 2 / 812) * 100)
          )}
        >
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: (396 / 812) * this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "bottom right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            {
              in: {
                style: {
                  transform: (i) =>
                    `translate(${300 * Math.pow(1 - i, 2)}px, ${
                      -300 * (1 - i)
                    }px)`,
                },
              },
            },
            ZoomIn(0.5, 1),
            Sticky(
              this.props.width < 900
                ? 50
                : (80 / this.props.width + 457 / 2 / 1440) * 100,
              this.props.width < 900
                ? ((305 + 286 / 2) / 812) * 100
                : ((454 + 275.5 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (311 / 375) * this.props.width
                  : (457 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (286 / 812) * this.props.height
                  : (275.5 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${BTGDM}")` : `url("${BTGD}")`,
              backgroundPosition:
                this.props.width < 900 ? `bottom` : "left bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            {
              in: {
                style: {
                  opacity: (i) => i * 3 - 2,
                },
              },
              out: {
                style: {
                  opacity: (i) => 1 - i,
                },
              },
            },
            Sticky(
              this.props.width < 900
                ? 50
                : (80 / this.props.width + 252 / 2 / 1440) * 100,
              this.props.width < 900
                ? ((623 + 35 / 2) / 812) * 100
                : ((790 + 38 / 2) / 1024) * 100
            )
          )}
        >
          <span
            className="hoverable-button"
            style={{
              // cursor: `url(${clickCursor}) 32 32, pointer`,
              fontSize:
                this.props.width < 900
                  ? (12 / 375) * this.props.width
                  : (14 / 1440) * this.props.width,
              display: "inline-block",
              width:
                this.props.width < 900
                  ? (150 / 375) * this.props.width
                  : (252 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (35 / 375) * this.props.width
                  : (34 / 1440) * this.props.width,
              lineHeight:
                this.props.width < 900
                  ? `${(35 / 375) * this.props.width}px`
                  : `${(38 / 1440) * this.props.width}px`,
              fontWeight: 700,
              border: "1px solid #fff",
              borderRadius:
                this.props.width < 900
                  ? (50 / 375) * this.props.width
                  : (50 / 1440) * this.props.width,
              textAlign: "center",
              fontFamily: "Helvetica",
            }}
            onClick={() =>
              window.open(
                "https://op3n.world/communities/bridge-the-gap/membership",
                "op3n"
              )
            }
            onMouseEnter={() => {
              document.querySelector("#cursor > svg.default").style.display =
                "none";
              document.querySelector("#cursor > svg.click").style.display =
                "block";
            }}
            onMouseLeave={() => {
              document.querySelector("#cursor > svg.click").style.display =
                "none";
              document.querySelector("#cursor > svg.default").style.display =
                "block";
            }}
          >
            MINT NOW
          </span>
        </Animator>
        <Animator
          animation={batch(
            {
              in: {
                style: {
                  opacity: (i) => i * 3,
                },
              },
              out: {
                style: {
                  opacity: (i) => 1 - i,
                },
              },
            },
            Sticky(
              this.props.width < 900 ? 50 : 99 - (903 / 2 / 1440) * 100,
              this.props.width < 900
                ? ((139 + 152 / 2) / 812) * 100
                : ((119 + 394 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (263 / 375) * this.props.width
                  : (903 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (152 / 812) * this.props.height
                  : (394 / 1440) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${BTGM}")` : `url("${BTG}")`,
              backgroundPosition: this.props.width < 900 ? `center` : "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
      </>
    );
  }
}

Welcome.propTypes = {
  width: PropTypes.number,
};

Welcome.defaultProps = {
  width: 1440,
};

export default Welcome;
