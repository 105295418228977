import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import RM from "../assets/Roadmap.png";
import RM1 from "../assets/Roadmap1.png";
import RM2 from "../assets/Roadmap2.png";
import RM1M from "../assets/Roadmap1Mobile.png";
import RM2M from "../assets/Roadmap2Mobile.png";
import BG from "../assets/s5bg.png";

class Roadmap extends React.Component {
  render() {
    return (
      <>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(-200),
            Sticky(50, 100 - ((17 + 310 / 2) / 812) * 100)
          )}
        >
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: (310 / 812) * this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "bottom left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            Sticky(
              this.props.width < 900
                ? 100 - (315 / 2 / 375) * 100
                : ((708 + 656 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((113 + 93 / 2) / 812) * 100
                : ((338 + 171 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (315 / 375) * this.props.width
                  : (656 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (93 / 812) * this.props.height
                  : (171 / 1024) * this.props.height,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url("${RM}")`,
                backgroundPosition: this.props.width < 900 ? "right" : "left",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></div>
          </div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(0, (772 / 2 / 1024) * this.props.height),
            Sticky(
              this.props.width < 900 ? 50 : ((115 + 466 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((206 + 537 / 2) / 812) * 100
                : 100 - (772 / 2 / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? this.props.width - 64
                  : (466 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (537 / 812) * this.props.height
                  : (772 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${RM1M}")` : `url("${RM1}")`,
              backgroundPosition: this.props.width < 900 ? "left" : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(0, (435 / 2 / 1024) * this.props.height),
            Sticky(
              this.props.width < 900
                ? 100 - (280 / 2 / 375) * 100
                : ((721 + 517 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((624 + 229 / 2) / 812) * 100
                : 100 - (435 / 2 / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (280 / 375) * this.props.width
                  : (517 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (229 / 812) * this.props.height
                  : (435 / 1024) * this.props.height,
              backgroundImage: this.props.width < 900 ? `` : `url("${RM2}")`,
              backgroundPosition: this.props.width < 900 ? "right" : "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
      </>
    );
  }
}

Roadmap.propTypes = {
  width: PropTypes.number,
};

Roadmap.defaultProps = {
  width: 1440,
};

export default Roadmap;
