import React from "react";
import Logo from "../assets/Logo.png";
import BG from "../assets/menu.png";
import Btn from "../assets/menuButton.png";
import Overlay from "../assets/menuOverlay.png";
import OverlayM from "../assets/menuOverlayMobile.png";
import clickCursor from "../assets/click.png";

export default class Nav extends React.Component {
  state = { openHamburger: false };

  render() {
    return (
      <div
        style={{
          height:
            this.props.width < 900
              ? (83 / 812) * this.props.height
              : (105 / 1024) * this.props.height,
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: this.props.width < 900 ? "flex-end" : "center",
          padding: this.props.width < 900 ? "0 32px" : "0 80px",
          boxSizing: "border-box",
          zIndex: 60,
        }}
      >
        <div
          style={{
            width:
              this.props.width < 900
                ? (57 / 375) * this.props.width
                : (119 / 1440) * this.props.width,
            height:
              this.props.width < 900
                ? (24 / 812) * this.props.height
                : (50 / 1024) * this.props.height,
            backgroundImage: `url("${Logo}")`,
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          onClick={() => {
            this.props.onClick && this.props.onClick(0);
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize:
              this.props.width < 900
                ? Math.min(
                    (16 / 375) * this.props.width,
                    (16 / 812) * this.props.height
                  )
                : Math.min(
                    (24 / 1440) * this.props.width,
                    (24 / 1024) * this.props.height
                  ),
            gap: Math.min(
              (24 / 1440) * this.props.width,
              (24 / 1024) * this.props.height
            ),
          }}
        >
          <div
            style={{
              // cursor: `url(${clickCursor}) 32 32, pointer`,
              width:
                this.props.width < 900
                  ? (42 / 375) * this.props.width
                  : (63 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (22 / 812) * this.props.height
                  : (34 / 1024) * this.props.height,
              backgroundImage: `url("${Btn}")`,
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
            onClick={() => {
              this.setState({ openHamburger: true });
            }}
            onMouseEnter={() => {
              document.querySelector("#cursor > svg.default").style.display =
                "none";
              document.querySelector("#cursor > svg.click").style.display =
                "block";
            }}
            onMouseLeave={() => {
              document.querySelector("#cursor > svg.click").style.display =
                "none";
              document.querySelector("#cursor > svg.default").style.display =
                "block";
            }}
          ></div>
        </div>
        <div
          style={{
            position: "absolute",
            left: "100%",
            top: 0,
            width:
              this.props.width < 900
                ? this.props.width
                : Math.min(
                    (466 / 1440) * this.props.width,
                    (466 / 1024) * this.props.height
                  ),
            height: this.props.height,
            backgroundImage: `url("${BG}")`,
            backgroundPosition: "right",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            transition: "transform 0.5s ease-in-out",
            transform: this.state.openHamburger
              ? "translateX(-100%)"
              : "translateX(0)",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: this.state.openHamburger ? this.props.width : 0,
              height: this.state.openHamburger ? this.props.height : 0,
            }}
            onClick={() => {
              this.setState({ openHamburger: false });
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              right:
                this.props.width < 900 ? "50%" : (80 / 1440) * this.props.width,
              top:
                this.props.width < 900
                  ? (45 / 812) * this.props.height
                  : 0.0322 * this.props.height,
              width:
                this.props.width < 900
                  ? Math.min(
                      (311 / 375) * this.props.width,
                      (311 / 812) * this.props.height
                    )
                  : Math.min(
                      (308 / 1440) * this.props.width,
                      (308 / 1024) * this.props.height
                    ),
              height:
                this.props.width < 900
                  ? Math.min(
                      (745 / 375) * this.props.width,
                      (745 / 812) * this.props.height
                    )
                  : Math.min(
                      (959 / 1440) * this.props.width,
                      (959 / 1024) * this.props.height
                    ),
              backgroundImage:
                this.props.width < 900
                  ? `url("${OverlayM}")`
                  : `url("${Overlay}")`,
              backgroundPosition: "top right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              transform: this.props.width < 900 ? "translateX(50%)" : "",
            }}
          >
            <div
              style={{
                // cursor: `url(${clickCursor}) 32 32, pointer`,
                position: "absolute",
                right: 0,
                top: 0,
                width:
                  this.props.width < 900
                    ? `${(42 / 311) * 100}%`
                    : (54 / 1440) * this.props.width,
                height:
                  this.props.width < 900
                    ? (42 / 812) * this.props.height
                    : (54 / 1024) * this.props.height,
              }}
              onClick={() => {
                this.setState({ openHamburger: false });
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(134 / 745) * 100}%`
                    : `${(176 / 959) * 100}%`,
                width: `${(156 / 308) * 100}%`,
                height: `${(37 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open(
                  "https://op3n.world/communities/bridge-the-gap/membership"
                );
                // setTimeout(() => this.setState({ openHamburger: false }), 500);
                // this.props.onClick && this.props.onClick(4);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(184 / 745) * 100}%`
                    : `${(233 / 959) * 100}%`,
                width: `${(262 / 308) * 100}%`,
                height: `${(37 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(4);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(234 / 745) * 100}%`
                    : `${(290 / 959) * 100}%`,
                width: `${(194 / 308) * 100}%`,
                height: `${(37 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(5);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(284 / 745) * 100}%`
                    : `${(347 / 959) * 100}%`,
                width: `${(115 / 308) * 100}%`,
                height: `${(29 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(0);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(326 / 745) * 100}%`
                    : `${(396 / 959) * 100}%`,
                width: `${(140 / 308) * 100}%`,
                height: `${(29 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(3);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(368 / 745) * 100}%`
                    : `${(445 / 959) * 100}%`,
                width: `${(102 / 308) * 100}%`,
                height: `${(29 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(6);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(410 / 745) * 100}%`
                    : `${(494 / 959) * 100}%`,
                width: `${(71 / 308) * 100}%`,
                height: `${(29 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(7);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(452 / 745) * 100}%`
                    : `${(543 / 959) * 100}%`,
                width: `${(199 / 308) * 100}%`,
                height: `${(29 / 959) * 100}%`,
              }}
              onClick={() => {
                setTimeout(() => this.setState({ openHamburger: false }), 500);
                this.props.onClick && this.props.onClick(9);
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(536 / 745) * 100}%`
                    : `${(667 / 959) * 100}%`,
                width: `${(158 / 308) * 100}%`,
                height: `${(16 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://vimeo.com/699097781");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(570 / 745) * 100}%`
                    : `${(703 / 959) * 100}%`,
                width: `${(154 / 308) * 100}%`,
                height: `${(16 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://discord.com/invite/bridgethegap");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-item"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(604 / 745) * 100}%`
                    : `${(739 / 959) * 100}%`,
                width: `${(123 / 308) * 100}%`,
                height: `${(16 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://op3n.world/communities/bridge-the-gap");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-button"
              style={{
                position: "absolute",
                left: 0,
                top:
                  this.props.width < 900
                    ? `${(683 / 745) * 100}%`
                    : `${(839 / 959) * 100}%`,
                width:
                  this.props.width < 900
                    ? `${(24 / 311) * 100}%`
                    : `${(32 / 308) * 100}%`,
                height:
                  this.props.width < 900
                    ? `${(24 / 745) * 100}%`
                    : `${(32 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://twitter.com/OP3Nworld_");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-button"
              style={{
                position: "absolute",
                left:
                  this.props.width < 900
                    ? `${(40 / 311) * 100}%`
                    : `${(48 / 308) * 100}%`,
                top:
                  this.props.width < 900
                    ? `${(683 / 745) * 100}%`
                    : `${(839 / 959) * 100}%`,
                width:
                  this.props.width < 900
                    ? `${(24 / 311) * 100}%`
                    : `${(32 / 308) * 100}%`,
                height:
                  this.props.width < 900
                    ? `${(24 / 745) * 100}%`
                    : `${(32 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://twitter.com/bridgethegapbtg");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
            <div
              className="nav-button"
              style={{
                position: "absolute",
                left:
                  this.props.width < 900
                    ? `${(80 / 311) * 100}%`
                    : `${(96 / 308) * 100}%`,
                top:
                  this.props.width < 900
                    ? `${(683 / 745) * 100}%`
                    : `${(839 / 959) * 100}%`,
                width:
                  this.props.width < 900
                    ? `${(24 / 311) * 100}%`
                    : `${(32 / 308) * 100}%`,
                height:
                  this.props.width < 900
                    ? `${(24 / 745) * 100}%`
                    : `${(32 / 959) * 100}%`,
              }}
              onClick={() => {
                window.open("https://discord.com/invite/bridgethegap");
              }}
              onMouseEnter={() => {
                document.querySelector("#cursor > svg.default").style.display =
                  "none";
                document.querySelector("#cursor > svg.click").style.display =
                  "block";
              }}
              onMouseLeave={() => {
                document.querySelector("#cursor > svg.click").style.display =
                  "none";
                document.querySelector("#cursor > svg.default").style.display =
                  "block";
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
