import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import NFT from "../assets/NFT.png";
import NFTM from "../assets/NFTMobile.png";
import NFTDM from "../assets/NFTDescMobile.png";
import Next from "../assets/next.png";
import NextM from "../assets/nextMobile.png";
import f1 from "../assets/flowers/_ n1.png";
import f2 from "../assets/flowers/_ n2.png";
import f3 from "../assets/flowers/_ n3.png";
import f4 from "../assets/flowers/_ n4.png";
import f5 from "../assets/flowers/_ n5.png";
import f6 from "../assets/flowers/_ n6.png";
import f7 from "../assets/flowers/_ n7.png";
import f8 from "../assets/flowers/_ n8.png";
import f9 from "../assets/flowers/_ n9.png";
import f10 from "../assets/flowers/_ n10.png";

import fh1 from "../assets/flowersHover/_ n1.png";
import fh2 from "../assets/flowersHover/_ n2.png";
import fh3 from "../assets/flowersHover/_ n3.png";
import fh4 from "../assets/flowersHover/_ n4.png";
import fh5 from "../assets/flowersHover/_ n5.png";
import fh6 from "../assets/flowersHover/_ n6.png";
import fh7 from "../assets/flowersHover/_ n7.png";
import fh8 from "../assets/flowersHover/_ n8.png";
import fh9 from "../assets/flowersHover/_ n9.png";
import fh10 from "../assets/flowersHover/_ n10.png";

import fc1 from "../assets/flowersClick/_ n1.png";
import fc2 from "../assets/flowersClick/_ n2.png";
import fc3 from "../assets/flowersClick/_ n3.png";
import fc4 from "../assets/flowersClick/_ n4.png";
import fc5 from "../assets/flowersClick/_ n5.png";
import fc6 from "../assets/flowersClick/_ n6.png";
import fc7 from "../assets/flowersClick/_ n7.png";
import fc8 from "../assets/flowersClick/_ n8.png";
import fc9 from "../assets/flowersClick/_ n9.png";
import fc10 from "../assets/flowersClick/_ n10.png";
import BG from "../assets/s3bg.png";

import clickCursor from "../assets/click.png";

let intervalListener = null;
let playIndicator = true;
let rotateCounter = 0;

let cache = Array(30)
  .fill(0)
  .map(
    (_, i) =>
      [
        fh1,
        fh2,
        fh3,
        fh4,
        fh5,
        fh6,
        fh7,
        fh8,
        fh9,
        fh10,
        fc1,
        fc2,
        fc3,
        fc4,
        fc5,
        fc6,
        fc7,
        fc8,
        fc9,
        fc10,
        f1,
        f2,
        f3,
        f4,
        f5,
        f6,
        f7,
        f8,
        f9,
        f10,
      ][i]
  )
  .map((i) => {
    const img = new Image();
    img.src = i;
    return img;
  });

class Nft extends React.Component {
  genAnimation(left, top, size, opacity, origin) {
    return batch(
      {
        in: {
          style: {
            opacity: (i) => {
              clearInterval(intervalListener);
              playIndicator = false;
              return i * opacity;
            },
            pointerEvents: "none",
          },
        },
        out: {
          style: {
            opacity: (i) => {
              if (i > 0) {
                clearInterval(intervalListener);
              }
              //clearInterval(intervalListener);
              // if (i === 0) {
              //   playIndicator = true;
              //   /*intervalListener = setInterval(() => {
              //     const newPSOArray = Array(10);
              //     for (let i = 0; i < 10; i++) {
              //       newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
              //     }
              //     this.setState({ psoArray: newPSOArray });
              //     rotateCounter++;
              //     if (rotateCounter >= 5) {
              //       //clearInterval(intervalListener);
              //       rotateCounter = 0;
              //     }
              //   }, 101); */
              // } else {
              //   playIndicator = false;
              // }
              return (1 - i) * opacity;
            },
            pointerEvents: opacity < 1 ? "none" : "all",
          },
        },
      },
      Sticky(
        this.props.width < 900
          ? ((left *
              Math.min(
                (size / 375) * this.props.width,
                (size / 812) * this.props.height
              )) /
              size /
              this.props.width +
              Math.min(
                (size / 375) * this.props.width,
                (size / 812) * this.props.height
              ) /
                2 /
                this.props.width +
              (this.props.width -
                (375 *
                  Math.min(
                    (size / 375) * this.props.width,
                    (size / 812) * this.props.height
                  )) /
                  size) /
                this.props.width) *
              100 -
              2
          : 50 +
              (-Math.min(0.5, 1440 / this.props.width / 2) +
                (left *
                  Math.min(
                    (size / 1440) * this.props.width,
                    (size / 1024) * this.props.height
                  )) /
                  size /
                  this.props.width +
                Math.min(
                  (size / 1440) * this.props.width,
                  (size / 1024) * this.props.height
                ) /
                  2 /
                  this.props.width) *
                100,
        this.props.width < 900
          ? 50 +
              (-Math.min(0.5, 812 / this.props.height / 2) +
                (top *
                  Math.min(
                    (size / 375) * this.props.width,
                    (size / 812) * this.props.height
                  )) /
                  size /
                  this.props.height +
                Math.min(
                  (size / 375) * this.props.width,
                  (size / 812) * this.props.height
                ) /
                  2 /
                  this.props.height) *
                100
          : 50 +
              (-Math.min(0.5, 1024 / this.props.height / 2) +
                (top *
                  Math.min(
                    (size / 1440) * this.props.width,
                    (size / 1024) * this.props.height
                  )) /
                  size /
                  this.props.height +
                Math.min(
                  (size / 1440) * this.props.width,
                  (size / 1024) * this.props.height
                ) /
                  2 /
                  this.props.height) *
                100
      ),
      {
        in: {
          style: {
            transform: (i) =>
              this.props.width < 900
                ? ``
                : i < 0.5
                ? `translate(${
                    (this.props.width / 2 +
                      Math.min(0.5, 1440 / this.props.width / 2) *
                        this.props.width -
                      (size / 2 / 1440) * this.props.width -
                      (left / Math.max(this.props.width, 1440)) *
                        this.props.width) *
                      (1 - i * 2) +
                    (Math.min(0.5, 1440 / this.props.width / 2) *
                      this.props.width -
                      (size / 2 / 1440) * this.props.width -
                      (left / Math.max(this.props.width, 1440)) *
                        this.props.width) *
                      (i * 2)
                  }px, ${
                    Math.min(0.5, 1024 / this.props.height / 2) *
                      this.props.height -
                    (size / 2 / 1024) * this.props.height -
                    (top / Math.max(this.props.height, 1024)) *
                      this.props.height
                  }px)`
                : `translate(${
                    (Math.min(0.5, 1440 / this.props.width / 2) *
                      this.props.width -
                      (size / 2 / 1440) * this.props.width -
                      (left / Math.max(this.props.width, 1440)) *
                        this.props.width) *
                    (2 - i * 2)
                  }px, ${
                    (Math.min(0.5, 1024 / this.props.height / 2) *
                      this.props.height -
                      (size / 2 / 1024) * this.props.height -
                      (top / Math.max(this.props.height, 1024)) *
                        this.props.height) *
                    (2 - i * 2)
                  }px)`,
          },
        },
      },
      {
        in: {
          style: {
            transform: (i) =>
              this.props.width < 900
                ? `scale(${size / 200})`
                : i < 0.5
                ? ``
                : `scale(${1 * (2 - i * 2) + (size / 200) * (i * 2 - 1)})`,
          },
        },
        out: {
          style: {
            transform: `scale(${size / 200})`,
            "--scaleFactor":
              this.props.width < 900 ? 320 / size : 417.39 / size,
            "--origin": origin,
          },
        },
      },
      {
        out: {
          style: {
            transition: (i) => (i === 0 ? "all 101ms linear" : ""),
          },
        },
      }
    );
  }

  moveArray = [1, 5, 6, 9, 2, 7, 0, 3, 4, 8];
  state = {
    psoArray:
      this.props.width < 900
        ? [
            [502, 592, 76, 0.2, "center"],
            [540, 471, 76, 0.2, "center"],
            [230, 635, 122, 1, "100% 160%"],
            [232, 247, 122, 1, "100% -60%"],
            [72, 599, 138, 1, "30% 135%"],
            [502, 349, 76, 0.2, "center"],
            [374, 629, 108, 0.3, "center"],
            [374, 267, 108, 0.3, "center"],
            [32, 425, 154, 1, "center left"],
            [72, 267, 138, 1, "30% -35%"],
          ]
        : [
            [1250, 509, 150, 0.2, "center"],
            [1250, 336, 150, 0.2, "center"],
            [765, 752, 240, 1, "center bottom"],
            [565, 104, 240, 1, "center top"],
            [440, 722, 270, 1, "center bottom"],
            [1100, 164, 150, 0.2, "center"],
            [1055, 694, 210, 0.3, "center"],
            [855, 104, 210, 0.3, "center"],
            [80, 481, 300, 1, "center left"],
            [240, 151, 270, 1, "center"],
          ],
  };

  render() {
    return (
      <>
        <Animator animation={batch(Fade(), MoveIn(200), Sticky(50, 50))}>
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: (330 / 812) * this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[0])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f1}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage = `url("${cache[0].src}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f1}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[1])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),

              backgroundImage: `url("${f2}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc2}")` : `url("${fh2}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f2}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[2])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f3}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc3}")` : `url("${fh3}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f3}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[3])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f4}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc4}")` : `url("${fh4}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f4}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[4])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f5}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc5}")` : `url("${fh5}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f5}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[5])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),

              backgroundImage: `url("${f6}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc6}")` : `url("${fh6}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f6}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[6])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),

              backgroundImage: `url("${f7}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc7}")` : `url("${fh7}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f7}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[7])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),

              backgroundImage: `url("${f8}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc8}")` : `url("${fh8}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f8}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[8])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f9}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc9}")` : `url("${fh9}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f9}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={this.genAnimation(...this.state.psoArray[9])}
        >
          <div
            style={{
              width: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              height: Math.min(
                this.props.width < 900
                  ? (200 / 375) * this.props.width
                  : (200 / 1440) * this.props.width,
                this.props.width < 900
                  ? (200 / 812) * this.props.height
                  : (200 / 1024) * this.props.height
              ),
              backgroundImage: `url("${f10}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              position: "relative",
              transformOrigin: "var(--origin)",
              zIndex: 0,
              transition: "transform 0.5s ease-in-out",
            }}
            onMouseOver={(e) => {
              //clearInterval(intervalListener);
              playIndicator = false;
              e.target.style.zIndex = 1;
              e.target.style.transform = "scale(var(--scaleFactor))";
              e.target.style.backgroundImage =
                this.props.width < 900 ? `url("${fc10}")` : `url("${fh10}")`;
            }}
            onMouseLeave={(e) => {
              //clearInterval(intervalListener);
              playIndicator = true;
              e.target.style.zIndex = 0;
              e.target.style.transform = "";
              e.target.style.backgroundImage = `url("${f10}")`;
              /*intervalListener = setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  //clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101); */
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={batch(
            Fade(),
            MoveIn(300, 0),
            Sticky(
              this.props.width < 900
                ? (312 / 2 / 375 + 32 / this.props.width) * 100
                : 100 - (564 / 2 / 1440 + 80 / this.props.width) * 100,
              this.props.width < 900
                ? ((105 + 84 / 2) / 812) * 100
                : ((243 + 198 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (312 / 375) * this.props.width
                  : (564 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (84 / 812) * this.props.height
                  : (198 / 1024) * this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${NFTDM}")` : ``,
              backgroundPosition: this.props.width < 900 ? "left" : "",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          className="animator-flower"
          animation={batch(
            Fade(),
            MoveIn(300, 0),
            Sticky(
              this.props.width < 900
                ? 100 - (133 / 2 / 375 + 32 / this.props.width) * 100
                : 100 - (564 / 2 / 1440 + 80 / this.props.width) * 100,
              this.props.width < 900
                ? ((378 + 90 / 2) / 812) * 100
                : ((243 + 198 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (133 / 375) * this.props.width
                  : (564 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (90 / 812) * this.props.height
                  : (198 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${NFTM}")` : `url("${NFT}")`,
              backgroundPosition:
                this.props.width < 900 ? "right" : "right bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            {
              in: {
                style: {
                  opacity: (i) => i * 3 - 2,
                },
              },
              out: {
                style: {
                  opacity: (i) => 1 - i,
                },
              },
            },
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 108 / 2 / 375) * 100
                : 100 - (252 / 2 / 1440 + 80 / this.props.width) * 100,
              this.props.width < 900
                ? ((197 + 27 / 2) / 812) * 100
                : ((465 + 38 / 2) / 1024) * 100
            )
          )}
        >
          <span
            className="hoverable-button"
            style={{
              // cursor: `url(${clickCursor}) 32 32, pointer`,
              fontSize:
                this.props.width < 900
                  ? (12 / 375) * this.props.width
                  : (14 / 1440) * this.props.width,
              display: "inline-block",
              width:
                this.props.width < 900
                  ? (108 / 375) * this.props.width
                  : (252 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (27 / 375) * this.props.width
                  : (38 / 1440) * this.props.width,
              lineHeight:
                this.props.width < 900
                  ? `${(27 / 375) * this.props.width}px`
                  : `${(38 / 1440) * this.props.width}px`,
              fontWeight: 700,
              border: "1px solid #fff",
              borderRadius:
                this.props.width < 900
                  ? (50 / 375) * this.props.width
                  : (50 / 1440) * this.props.width,
              textAlign: "center",
              fontFamily: "Helvetica",
            }}
            onClick={() =>
              window.open(
                "https://op3n.world/communities/bridge-the-gap/membership",
                "op3n"
              )
            }
            onMouseEnter={() => {
              document.querySelector("#cursor > svg.default").style.display =
                "none";
              document.querySelector("#cursor > svg.click").style.display =
                "block";
            }}
            onMouseLeave={() => {
              document.querySelector("#cursor > svg.click").style.display =
                "none";
              document.querySelector("#cursor > svg.default").style.display =
                "block";
            }}
          >
            MINT NOW
          </span>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            Sticky(
              this.props.width < 900
                ? 100 - (32 / this.props.width + 60 / 2 / 375) * 100
                : 100 - ((80 + 97 / 2) / this.props.width) * 100,
              this.props.width < 900
                ? ((475 + 60 / 2) / 812) * 100
                : 100 - ((55 + 97 / 2) / this.props.height) * 100
            )
          )}
        >
          <div
            id="next-button"
            style={{
              width: this.props.width < 900 ? 60 : 97,
              height: this.props.width < 900 ? 60 : 97,
              backgroundImage:
                this.props.width < 900 ? `url("${Next}")` : `url("${Next}")`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              // cursor: `url(${clickCursor}) 32 32, pointer`,
              borderRadius: "50%",
            }}
            onClick={() => {
              clearInterval(intervalListener);
              intervalListener = window.setInterval(() => {
                const newPSOArray = Array(10);
                for (let i = 0; i < 10; i++) {
                  newPSOArray[i] = this.state.psoArray[this.moveArray[i]];
                }
                this.setState({ psoArray: newPSOArray });
                rotateCounter++;
                if (rotateCounter >= 5) {
                  clearInterval(intervalListener);
                  rotateCounter = 0;
                }
              }, 101);
            }}
            onMouseEnter={() => {
              document.querySelector("#cursor > svg.default").style.display =
                "none";
              document.querySelector("#cursor > svg.click").style.display =
                "block";
            }}
            onMouseLeave={() => {
              document.querySelector("#cursor > svg.click").style.display =
                "none";
              document.querySelector("#cursor > svg.default").style.display =
                "block";
            }}
          />
        </Animator>
      </>
    );
  }
}

Nft.propTypes = {
  width: PropTypes.number,
};

Nft.defaultProps = {
  width: 1440,
};

export default Nft;
