import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
  FadeOut,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import Exc from "../assets/Exclusive.png";
import ExcM from "../assets/ExclusiveMobile.png";
import ExcD from "../assets/ExclusiveDesc.png";
import ExcDM from "../assets/ExclusiveDescMobile.png";
import VL from "../video/00.00.h264.mp4";
import VR from "../video/00.01.h264.mp4";
import BG from "../assets/s4bg.png";
import MB from "../assets/moveButton.png";

class NftExclusive extends React.Component {
  render() {
    return (
      <>
        <Animator
          animation={batch(
            { in: { style: { pointerEvents: "none" } } },
            Fade(),
            Sticky(50, ((87 + 405 / 2) / 812) * 100)
          )}
        >
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: (405 / 812) * this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            { in: { style: { pointerEvents: "none" } } },
            FadeIn(-1, 2),
            FadeOut(1, 0),
            // MoveIn(0, -200),
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 315 / 2 / 375) * 100
                : ((18 + 504 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((109 + 112 / 2) / 812) * 100
                : ((153 + 180 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (315 / 375) * this.props.width
                  : (504 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (112 / 812) * this.props.height
                  : (180 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${ExcM}")` : `url("${Exc}")`,
              backgroundPosition: this.props.width < 900 ? "left" : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            { in: { style: { pointerEvents: "none" } } },
            FadeIn(0, 3),
            FadeOut(1, 0),
            MoveIn(0, 200),
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 630 / 2 / 375) * 100
                : ((178 + 1102 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((345 + 311 / 2) / 812) * 100
                : ((365 + 500 / 2) / 1024) * 100
            )
          )}
        >
          <div
            className={this.props.width < 900 ? "scroll-bar" : ""}
            style={{
              width:
                this.props.width < 900
                  ? Math.min(
                      (630 / 375) * this.props.width,
                      (630 / 812) * this.props.height
                    )
                  : Math.min(
                      (1102 / 1440) * this.props.width,
                      (1102 / 1024) * this.props.height
                    ),
              height:
                this.props.width < 900
                  ? Math.min(
                      (311 / 375) * this.props.width,
                      (311 / 812) * this.props.height
                    )
                  : Math.min(
                      (500 / 1440) * this.props.width,
                      (500 / 1024) * this.props.height
                    ),
              display: "flex",
              // flexDirection: this.props.width < 900 ? "column" : "row",
              alienItems: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                position: "relative",
                border: "1px solid #fff",
                height: "100%",
                width: "auto",
              }}
            >
              <video
                src={VL}
                autoPlay
                playsInline
                muted
                loop
                height="100%"
                width="100%"
              ></video>
              <span
                style={{
                  position: "absolute",
                  textAlign: "center",
                  width: "100%",
                  left: 0,
                  bottom: this.props.width < 900 ? -28 : 8,
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                  fontSize:
                    this.props.width < 900
                      ? Math.min(
                          (14 / 375) * this.props.width,
                          (14 / 812) * this.props.height
                        )
                      : Math.min(
                          (18 / 1440) * this.props.width,
                          (18 / 1024) * this.props.height
                        ),
                }}
              >
                00.00 | BOARD OF ADVISOR | 60 AVAX
              </span>
            </div>
            <div
              style={{
                position: "relative",
                border: "1px solid #fff",
                height: "100%",
                width: "auto",
                marginLeft: 8,
              }}
            >
              <video
                src={VR}
                autoPlay
                playsInline
                muted
                loop
                height="100%"
                width="100%"
              ></video>
              <span
                style={{
                  position: "absolute",
                  textAlign: "center",
                  width: "100%",
                  left: 0,
                  bottom: this.props.width < 900 ? -28 : 8,
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                  fontSize:
                    this.props.width < 900
                      ? Math.min(
                          (14 / 375) * this.props.width,
                          (14 / 812) * this.props.height
                        )
                      : Math.min(
                          (18 / 1440) * this.props.width,
                          (18 / 1024) * this.props.height
                        ),
                }}
              >
                00.01 | BTG COMMITTEE MEMBER | 12 AVAX
              </span>
            </div>
          </div>
        </Animator>
        <Animator
          animation={batch(
            { in: { style: { pointerEvents: "none" } } },
            FadeIn(0, 3),
            FadeOut(1, 0),
            MoveIn(0, 200),
            Sticky(50, ((694 + 64 / 2) / 812) * 100)
          )}
        >
          <div
            style={{
              width: this.props.width < 900 ? 192 : 0,
              height:
                this.props.width < 900 ? (64 / 812) * this.props.height : 0,
              display: this.props.width < 900 ? "flex" : "none",
              justifyContent: "space-between",
            }}
          >
            <div
              className="scroll-bar-a"
              style={{
                width: (64 / 812) * this.props.height,
                height: (64 / 812) * this.props.height,
                background: `url("${MB}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                transform: "rotate(180deg)",
                opacity: 0.5,
                borderRadius: "50%",
              }}
              onClick={() => {
                document.querySelector(".scroll-bar").style.transform = "";
                document.querySelector(".scroll-bar-a").style.opacity = 0.5;
                document.querySelector(".scroll-bar-b").style.opacity = 1;
              }}
            ></div>
            <div
              className="scroll-bar-b"
              style={{
                width: (64 / 812) * this.props.height,
                height: (64 / 812) * this.props.height,
                background: `url("${MB}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "50%",
              }}
              onClick={() => {
                document.querySelector(".scroll-bar").style.transform =
                  "translateX(calc(-100vw + 64px - 8px))";
                document.querySelector(".scroll-bar-a").style.opacity = 1;
                document.querySelector(".scroll-bar-b").style.opacity = 0.5;
              }}
            ></div>
          </div>
        </Animator>
        <Animator
          animation={batch(
            { in: { style: { pointerEvents: "none" } } },
            FadeIn(-2, 1),
            FadeOut(1, 0),
            // MoveIn(0, -200),
            Sticky(
              this.props.width < 900
                ? 50
                : 100 - (754 / 2 / 1440 + 80 / this.props.width) * 100,
              this.props.width < 900
                ? ((210 + 117 / 2) / 812) * 100
                : ((143 + 146 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? this.props.width - 64
                  : (754 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (117 / 812) * this.props.height
                  : (146 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${ExcDM}")` : `url("${ExcD}")`,
              backgroundPosition: this.props.width < 900 ? "left" : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            {
              in: {
                style: {
                  opacity: (i) => i * 3 - 2,
                },
              },
              out: {
                style: {
                  opacity: (i) => 1 - i,
                },
              },
            },
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 108 / 2 / 375) * 100
                : 100 -
                    (Math.min(
                      (486 / 1440) * this.props.width,
                      (486 / 1024) * this.props.height
                    ) /
                      this.props.width +
                      252 / 2 / 1440 +
                      80 / this.props.width) *
                      100,
              this.props.width < 900
                ? ((300 + 27 / 2) / 812) * 100
                : ((251 + 38 / 2) / 1024) * 100
            )
          )}
        >
          <span
            className="hoverable-button"
            style={{
              // cursor: `url(${clickCursor}) 32 32, pointer`,
              fontSize:
                this.props.width < 900
                  ? (12 / 375) * this.props.width
                  : (14 / 1440) * this.props.width,
              display: "inline-block",
              width:
                this.props.width < 900
                  ? (108 / 375) * this.props.width
                  : (252 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (27 / 375) * this.props.width
                  : (38 / 1440) * this.props.width,
              lineHeight:
                this.props.width < 900
                  ? `${(27 / 375) * this.props.width}px`
                  : `${(38 / 1440) * this.props.width}px`,
              fontWeight: 700,
              border: "1px solid #fff",
              borderRadius:
                this.props.width < 900
                  ? (50 / 375) * this.props.width
                  : (50 / 1440) * this.props.width,
              textAlign: "center",
              fontFamily: "Helvetica",
            }}
            onClick={() =>
              window.open(
                "https://op3n.world/communities/bridge-the-gap/membership",
                "op3n"
              )
            }
            onMouseEnter={() => {
              document.querySelector("#cursor > svg.default").style.display =
                "none";
              document.querySelector("#cursor > svg.click").style.display =
                "block";
            }}
            onMouseLeave={() => {
              document.querySelector("#cursor > svg.click").style.display =
                "none";
              document.querySelector("#cursor > svg.default").style.display =
                "block";
            }}
          >
            MINT NOW
          </span>
        </Animator>
      </>
    );
  }
}

NftExclusive.propTypes = {
  width: PropTypes.number,
};

NftExclusive.defaultProps = {
  width: 1440,
};

export default NftExclusive;
