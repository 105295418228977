import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import React from "react";
import { Manager, Pan, DIRECTION_VERTICAL } from "hammerjs";
import TWEEN from "@tweenjs/tween.js";
import "./App.css";
import Loading from "./assets/Loading.png";
import Next from "./assets/next.png";
import NextM from "./assets/nextMobile.png";
import bodymovin from "lottie-web";
import SI from "./assets/scrollIndicator.png";
import BTGVideo from "./video/BTG.h264.mp4";
import Nav from "./components/Nav";
import Trailer from "./components/Trailer";
import Welcome from "./components/Welcome";
import Curriculum from "./components/Curriculum";
import Nft from "./components/Nft";
import Roadmap from "./components/Roadmap";
import Ethos from "./components/Ethos";
import Sale from "./components/Sale";
import Team from "./components/Team";
import NftExclusive from "./components/NftExclusive";
// PLOPINJECTIMPORT

const VideoLoader = () => import("./sequence/Video_Edited_3");
let Video;
let globalTimeout;
let globalTimeout2;
let tweenNode;

function refresh(delta) {
  TWEEN.update(delta);
  requestAnimationFrame(refresh);
}
requestAnimationFrame(refresh);

let listeningImg = null;

let pointer = 0;
let block = false;
const isChrome =
  typeof window !== "undefined"
    ? /(chrome|crios)/.test(window.navigator.userAgent.toLowerCase())
    : false;

function App() {
  const [loading, setLoading] = React.useState(true);
  const [width, setWidth] = React.useState(
    document.documentElement.clientWidth
  );
  const [height, setHeight] = React.useState(window.innerHeight);

  const refs = [
    React.useRef(null),
    // PLOPINJECTREF
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  React.useLayoutEffect(() => {
    setWidth(document.documentElement.clientWidth);
    setHeight(window.innerHeight);

    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(document.documentElement.clientWidth);
      requestAnimationFrame(redrawBG);
      block = true;
      const body = document.body,
        html = document.documentElement;
      const height =
        Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ) - window.innerHeight;
      // if (tweenNode) {
      //   tweenNode.to({
      //     y: refs[pointer].current.offsetTop,
      //   });
      // }
      if (tweenNode) tweenNode.stop();
      tweenNode = new TWEEN.Tween({
        y: window.scrollY,
      })
        .to(
          {
            y: refs[pointer].current.offsetTop,
          },
          Math.abs(
            ((refs[pointer].current.offsetTop - window.scrollY) / height) *
              refs.length *
              2000
          )
        )
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onStart(() => {})
        .onUpdate((t) => {
          window.scrollTo(0, t.y);
        })
        .onComplete(() => {
          block = false;
        })
        .start();
    });

    function redrawBG() {
      if (!Video) return;
      const body = document.body,
        html = document.documentElement;
      const height =
        Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ) - window.innerHeight;
      const ratio = height === 0 ? 0 : window.scrollY / height;

      let justifyRatio =
        ((ratio - 1 / refs.length) / (refs.length - 2)) * refs.length;
      if (justifyRatio > (refs.length - 3) / (refs.length - 2)) {
        justifyRatio =
          (justifyRatio - (refs.length - 3) / (refs.length - 2)) / 1.5 +
          (refs.length - 3) / (refs.length - 2);
      } else if (justifyRatio < 1 / (refs.length - 2)) {
        justifyRatio =
          (justifyRatio - 1 / (refs.length - 2)) / 1 +
          (3 - 1 / 1.6 - 1 / 0.7) / (refs.length - 2);
      } else if (justifyRatio < 2 / (refs.length - 2)) {
        justifyRatio =
          (justifyRatio - 2 / (refs.length - 2)) / 0.7 +
          (3 - 1 / 1.6) / (refs.length - 2);
      } else if (justifyRatio < 3 / (refs.length - 2)) {
        justifyRatio =
          (justifyRatio - 3 / (refs.length - 2)) / 1.6 + 3 / (refs.length - 2);
      }
      if (justifyRatio < 0) {
        const canvas = document.getElementById("BGVideo");
        const ctx = canvas.getContext("2d");
        ctx.clearRect(
          0,
          0,
          document.documentElement.clientWidth,
          window.innerHeight
        );
      } else {
        const img = Video(justifyRatio);
        if (img.complete && img.naturalWidth) {
          listeningImg = null;
          const canvas = document.getElementById("BGVideo");
          const ctx = canvas.getContext("2d");
          ctx.clearRect(
            0,
            0,
            document.documentElement.clientWidth,
            window.innerHeight
          );
          const w =
            Math.max(
              document.documentElement.clientWidth / 1440,
              window.innerHeight / 1024
            ) * 1440;
          const h = (w / 1440) * 1024;
          const x = (document.documentElement.clientWidth - w) / 2;
          const y = (window.innerHeight - h) / 2;
          ctx.drawImage(img, x, y, w, h);
        } else {
          listeningImg = img;
          img.onload = () => {
            if (listeningImg === img) {
              const canvas = document.getElementById("BGVideo");
              const ctx = canvas.getContext("2d");
              ctx.clearRect(
                0,
                0,
                document.documentElement.clientWidth,
                window.innerHeight
              );
              const w =
                Math.max(
                  document.documentElement.clientWidth / 1440,
                  window.innerHeight / 1024
                ) * 1440;
              const h = (w / 1440) * 1024;
              const x = (document.documentElement.clientWidth - w) / 2;
              const y = (window.innerHeight - h) / 2;
              ctx.drawImage(img, x, y, w, h);
            }
          };
        }
      }
    }

    window.addEventListener("scroll", () => {
      if (globalTimeout) {
        clearTimeout(globalTimeout);
      }
      if (width >= 900) redrawBG();
      globalTimeout = setTimeout(() => {
        block = true;
        const body = document.body,
          html = document.documentElement;
        const height =
          Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
          ) - window.innerHeight;
        let ratio = height === 0 ? 0 : window.scrollY / height;
        ratio *= refs.length - 1;
        const nearestRatio = Math.round(ratio);
        pointer = nearestRatio;
        if (ratio !== nearestRatio) {
          if (tweenNode) tweenNode.stop();
          tweenNode = new TWEEN.Tween({
            y: window.scrollY,
            // right: parseInt(document.getElementById("next-button").style.right),
            // bottom: parseInt(
            //   document.getElementById("next-button").style.bottom
            // ),
            // opacity: parseFloat(
            //   document.getElementById("next-button").style.opacity
            // ),
          })
            .to(
              {
                y: refs[pointer].current.offsetTop,
                // right:
                //   pointer < refs.length - 2
                //     ? width < 900
                //       ? 32
                //       : 80
                //     : width < 900
                //     ? width / 2 - 30
                //     : width / 2 - 48.5,
                // bottom:
                //   pointer < refs.length - 2
                //     ? width < 900
                //       ? 62
                //       : 55
                //     : width < 900
                //     ? (321 / 812) * height
                //     : (273 / 1024) * height,
                // opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
              },
              Math.abs((nearestRatio - ratio) * 2000)
            )
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onStart(() => {
              // document.getElementById('BGVideo').play()
            })
            .onUpdate((t) => {
              window.scrollTo(0, t.y);
              // const nextBtn = document.getElementById("next-button");
              // nextBtn.style.right = t.right + "px";
              // nextBtn.style.bottom = t.bottom + "px";
              // nextBtn.style.opacity = t.opacity;
            })
            .onComplete(() => {
              block = false;
              // document.getElementById('BGVideo').pause()
            })
            .start();
        } else {
          block = false;
        }
      }, 500);

      if (document.getElementById("trailer_v")) {
        const rect = document
          .getElementById("trailer_v")
          .getBoundingClientRect();
        if (
          rect.top < 0 ||
          rect.bottom >
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          document.getElementById("trailer_v").pause();
        }
      }
    });
    requestAnimationFrame(redrawBG);

    const hammer = new Manager(window.document.body);

    hammer.add(new Pan({ direction: DIRECTION_VERTICAL }));
    hammer.on("panup", () => {
      if (block) {
        return;
      }
      document.getElementById("starter-video").play();
      block = true;
      const oldPointer = pointer;
      pointer++;
      if (pointer >= refs.length - 1) {
        pointer = refs.length - 1;
      }
      if (block) {
        const sectionDelta = pointer - oldPointer;
        if (tweenNode) tweenNode.stop();
        tweenNode = new TWEEN.Tween({
          y: window.scrollY,
          // right: parseInt(document.getElementById("next-button").style.right),
          // bottom: parseInt(document.getElementById("next-button").style.bottom),
          // opacity: parseFloat(
          //   document.getElementById("next-button").style.opacity
          // ),
        })
          .to(
            {
              y: refs[pointer].current.offsetTop,
              right:
                pointer < refs.length - 2
                  ? width < 900
                    ? 32
                    : 80
                  : width < 900
                  ? width / 2 - 30
                  : width / 2 - 48.5,
              bottom:
                pointer < refs.length - 2
                  ? width < 900
                    ? 62
                    : 55
                  : width < 900
                  ? (321 / 812) * height
                  : (273 / 1024) * height,
              opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
            },
            Math.abs(sectionDelta * 2000)
          )
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onStart(() => {
            // document.getElementById('BGVideo').play()
          })
          .onUpdate((t) => {
            window.scrollTo(0, t.y);
            // const nextBtn = document.getElementById("next-button");
            // nextBtn.style.right = t.right + "px";
            // nextBtn.style.bottom = t.bottom + "px";
            // nextBtn.style.opacity = t.opacity;
          })
          .onComplete(() => {
            block = false;
            // document.getElementById('BGVideo').pause()
          })
          .start();
      }
    });
    hammer.on("pandown", () => {
      if (block) {
        return;
      }
      block = true;
      const oldPointer = pointer;
      pointer--;
      if (pointer <= 0) {
        pointer = 0;
      }
      if (block) {
        const sectionDelta = pointer - oldPointer;
        if (tweenNode) tweenNode.stop();
        tweenNode = new TWEEN.Tween({
          y: window.scrollY,
          // right: parseInt(document.getElementById("next-button").style.right),
          // bottom: parseInt(document.getElementById("next-button").style.bottom),
          // opacity: parseFloat(
          //   document.getElementById("next-button").style.opacity
          // ),
        })
          .to(
            {
              y: refs[pointer].current.offsetTop,
              right:
                pointer < refs.length - 2
                  ? width < 900
                    ? 32
                    : 80
                  : width < 900
                  ? width / 2 - 30
                  : width / 2 - 48.5,
              bottom:
                pointer < refs.length - 2
                  ? width < 900
                    ? 62
                    : 55
                  : width < 900
                  ? (321 / 812) * height
                  : (273 / 1024) * height,
              opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
            },
            Math.abs(sectionDelta * 2000)
          )
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onStart(() => {
            // document.getElementById('BGVideo').play()
          })
          .onUpdate((t) => {
            window.scrollTo(0, t.y);
            // const nextBtn = document.getElementById("next-button");
            // nextBtn.style.right = t.right + "px";
            // nextBtn.style.bottom = t.bottom + "px";
            // nextBtn.style.opacity = t.opacity;
          })
          .onComplete(() => {
            block = false;
            // document.getElementById('BGVideo').pause()
          })
          .start();
      }
    });

    window.addEventListener(
      "mousewheel",
      (e) => {
        if (block) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          return;
        }
        document.getElementById("starter-video").play();
        block = true;
        const oldPointer = pointer;
        if (e.deltaY > 0) {
          pointer++;
          if (pointer >= refs.length - 1) {
            pointer = refs.length - 1;
          }
        } else if (e.deltaY < 0) {
          pointer--;
          if (pointer <= 0) {
            pointer = 0;
          }
        } else {
          block = false;
        }
        if (block) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          const sectionDelta = pointer - oldPointer;
          if (tweenNode) tweenNode.stop();
          tweenNode = new TWEEN.Tween({
            y: window.scrollY,
            // right: parseInt(document.getElementById("next-button").style.right),
            // bottom: parseInt(
            //   document.getElementById("next-button").style.bottom
            // ),
            // opacity: parseFloat(
            //   document.getElementById("next-button").style.opacity
            // ),
          })
            .to(
              {
                y: refs[pointer].current.offsetTop,
                right:
                  pointer < refs.length - 2
                    ? width < 900
                      ? 32
                      : 80
                    : width < 900
                    ? width / 2 - 30
                    : width / 2 - 48.5,
                bottom:
                  pointer < refs.length - 2
                    ? width < 900
                      ? 62
                      : 55
                    : width < 900
                    ? (321 / 812) * height
                    : (273 / 1024) * height,
                opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
              },
              Math.abs(sectionDelta * 2000)
            )
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onStart(() => {
              // document.getElementById('BGVideo').play()
            })
            .onUpdate((t) => {
              window.scrollTo(0, t.y);
              // const nextBtn = document.getElementById("next-button");
              // nextBtn.style.right = t.right + "px";
              // nextBtn.style.bottom = t.bottom + "px";
              // nextBtn.style.opacity = t.opacity;
            })
            .onComplete(() => {
              block = false;
              // document.getElementById('BGVideo').pause()
            })
            .start();
        }
      },
      {
        passive: false,
      }
    );

    bodymovin.loadAnimation({
      wrapper: document.querySelector("#cursor > svg.default"),
      animType: "svg",
      loop: true,
      path: "./cursors/default_scroll.json",
    });

    bodymovin.loadAnimation({
      wrapper: document.querySelector("#cursor > svg.click"),
      animType: "svg",
      loop: true,
      path: "./cursors/Click_Button.json",
    });

    window.addEventListener("mousemove", (e) => {
      document.getElementById("cursor").style.left = e.clientX + "px";
      document.getElementById("cursor").style.top = e.clientY + "px";
    });
  }, [loading]);

  if (loading) {
    VideoLoader().then((m) => {
      Video = m.default;
      setLoading(false);
    });
    return (
      <div
        style={{
          position: "absolute",
          left: "50vw",
          top: "50vh",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="loading-spin"
          style={{
            width: 89,
            height: 89,
            backgroundImage: `url("${Loading}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <p>LOADING...</p>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Nav
          width={width}
          height={height}
          onClick={(i) => {
            // const div = refs[i].current
            pointer = i;
            if (tweenNode) tweenNode.stop();
            tweenNode = new TWEEN.Tween({
              y: window.scrollY,
              // right: parseInt(
              //   document.getElementById("next-button").style.right
              // ),
              // bottom: parseInt(
              //   document.getElementById("next-button").style.bottom
              // ),
              // opacity: parseFloat(
              //   document.getElementById("next-button").style.opacity
              // ),
            })
              .to(
                {
                  y: refs[pointer].current.offsetTop,
                  right:
                    pointer < refs.length - 2
                      ? width < 900
                        ? 32
                        : 80
                      : width < 900
                      ? width / 2 - 30
                      : width / 2 - 48.5,
                  bottom:
                    pointer < refs.length - 2
                      ? width < 900
                        ? 62
                        : 55
                      : width < 900
                      ? (321 / 812) * height
                      : (273 / 1024) * height,
                  opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
                },
                1000
              )
              .easing(TWEEN.Easing.Quadratic.InOut)
              .onStart(() => {
                block = true;
                // document.getElementById('BGVideo').play()
              })
              .onUpdate((t) => {
                window.scrollTo(0, t.y);
                // const nextBtn = document.getElementById("next-button");
                // nextBtn.style.right = t.right + "px";
                // nextBtn.style.bottom = t.bottom + "px";
                // nextBtn.style.opacity = t.opacity;
              })
              .onComplete(() => {
                block = false;
                // document.getElementById('BGVideo').pause()
              })
              .start();
          }}
        />
        <canvas
          id="BGVideo"
          width={width}
          height={height}
          style={{
            width,
            height,
            position: "fixed",
            left: 0,
            top: 0,
            objectFit: "cover",
            display: width < 900 ? "none" : "block",
          }}
        />
        <ScrollContainer>
          <div ref={refs[0]} style={{ position: "relative", zIndex: 9 }}>
            <ScrollPage page={0}>
              <video
                id="starter-video"
                src={BTGVideo}
                autoPlay
                muted
                playsInline
                style={{
                  width,
                  height,
                  position: "absolute",
                  left: 0,
                  top: 0,
                  objectFit: "cover",
                }}
                onDurationChange={() => {
                  setTimeout(() => {
                    document.getElementById("starter-video").pause();
                    document.getElementById("starter-video").loop = true;
                  }, 8000);
                }}
              />
              <div
                className="scroll-indicator"
                style={{
                  width,
                  height: 0.15 * height,
                  position: "absolute",
                  left: 0,
                  bottom: 25,
                  backgroundImage: `url("${SI}")`,
                  backgroundPosition: "bottom center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />
            </ScrollPage>
          </div>
          <div ref={refs[1]} style={{ position: "relative", zIndex: 8 }}>
            <ScrollPage page={1}>
              <Trailer width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[2]} style={{ position: "relative", zIndex: 7 }}>
            <ScrollPage page={2}>
              <Welcome width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[3]} style={{ position: "relative", zIndex: 6 }}>
            <ScrollPage page={3}>
              <Curriculum width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[4]} style={{ position: "relative", zIndex: 5 }}>
            <ScrollPage page={4}>
              <Nft width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[5]} style={{ position: "relative", zIndex: 4 }}>
            <ScrollPage page={5}>
              <NftExclusive width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[6]} style={{ position: "relative", zIndex: 3 }}>
            <ScrollPage page={6}>
              <Roadmap width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[7]} style={{ position: "relative", zIndex: 2 }}>
            <ScrollPage page={7}>
              <Ethos width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[8]} style={{ position: "relative", zIndex: 1 }}>
            <ScrollPage page={8}>
              <Sale width={width} height={height} />
            </ScrollPage>
          </div>
          <div ref={refs[9]} style={{ position: "relative", zIndex: 0 }}>
            <ScrollPage page={9}>
              <Team width={width} height={height} />
            </ScrollPage>
          </div>
          {/* PLOPINJECTDOM */}
        </ScrollContainer>
        {/* <div
          id="next-button"
          style={{
            position: "fixed",
            right: width < 900 ? 32 : 80,
            bottom: width < 900 ? 62 : 55,
            width: width < 900 ? 60 : 97,
            height: width < 900 ? 60 : 97,
            opacity: 0,
            backgroundImage:
              width < 900 ? `url("${NextM}")` : `url("${Next}")`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            cursor: "pointer",
            borderRadius: "50%",
          }}
          onClick={() => {
            if (block) {
              return;
            }
            document.getElementById("starter-video").play();
            block = true;
            const oldPointer = pointer;
            pointer++;
            if (pointer >= refs.length - 1) {
              pointer = refs.length - 1;
            }
            if (block) {
              const sectionDelta = pointer - oldPointer;
              if(tweenNode)tweenNode.stop();
tweenNode=new TWEEN.Tween({
                y: window.scrollY,
                right: parseInt(
                  document.getElementById("next-button").style.right
                ),
                bottom: parseInt(
                  document.getElementById("next-button").style.bottom
                ),
                opacity: parseFloat(
                  document.getElementById("next-button").style.opacity
                ),
              })
                .to(
                  {
                    y: refs[pointer].current.offsetTop,
                    right:
                      pointer < refs.length - 2
                        ? width < 900
                          ? 32
                          : 80
                        : width < 900
                        ? width / 2 - 30
                        : width / 2 - 48.5,
                    bottom:
                      pointer < refs.length - 2
                        ? width < 900
                          ? 62
                          : 55
                        : width < 900
                        ? (321 / 812) * height
                        : (273 / 1024) * height,
                    opacity: pointer >= 1 && pointer <= refs.length - 2 ? 1 : 0,
                  },
                  Math.abs(sectionDelta * 2000)
                )
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onStart(() => {
                  // document.getElementById('BGVideo').play()
                })
                .onUpdate((t) => {
                  window.scrollTo(0, t.y);
                  const nextBtn = document.getElementById("next-button");
                  nextBtn.style.right = t.right + "px";
                  nextBtn.style.bottom = t.bottom + "px";
                  nextBtn.style.opacity = t.opacity;
                })
                .onComplete(() => {
                  block = false;
                  // document.getElementById('BGVideo').pause()
                })
                .start();
            }
          }}
        ></div> */}
        <div
          id="cursor"
          style={{
            position: "fixed",
            zIndex: 666,
            transform: "translate(-50%,-50%)",
            pointerEvents: "none",
            display: width < 900 ? "none" : "block",
          }}
        >
          <svg
            className="default"
            viewBox="200 200 100 100"
            width={100}
            height={100}
          />
          <svg
            className="click"
            viewBox="200 200 100 100"
            width={100}
            height={100}
            style={{
              display: "none",
            }}
          />
        </div>
      </div>
    );
  }
}

export default App;
