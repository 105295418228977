import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import T from "../assets/Team.png";
import TM from "../assets/TeamMobile.png";
import BG from "../assets/s8bg.png";

class Team extends React.Component {
  render() {
    return (
      <>
        <Animator animation={batch(Fade(), Sticky(50, 50))}>
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            Sticky(
              this.props.width < 900 ? 50 : ((49 + 1313 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((227 + 379 / 2) / 812) * 100
                : ((345 + 492 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (314 / 375) * this.props.width
                  : (1313 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (600 / 812) * this.props.height
                  : (492 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${TM}")` : `url("${T}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
      </>
    );
  }
}

Team.propTypes = {
  width: PropTypes.number,
};

Team.defaultProps = {
  width: 1440,
};

export default Team;
