import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import PS from "../assets/PublicSale.png";
import BG from "../assets/s7bg.png";

class Sale extends React.Component {
  render() {
    return (
      <>
        <Animator
          animation={batch(Fade(), MoveIn(50), ZoomIn(1.5, 1), Sticky(50, 50))}
        >
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator animation={batch(Fade(), Sticky(50, 45))}>
          <div
            style={{
              width:
                this.props.width < 900
                  ? this.props.width - 64
                  : (619 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (120 / 812) * this.props.height
                  : (134 / 1024) * this.props.height,
              backgroundImage: `url("${PS}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
      </>
    );
  }
}

Sale.propTypes = {
  width: PropTypes.number,
};

Sale.defaultProps = {
  width: 1440,
};

export default Sale;
