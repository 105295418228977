import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
  FadeOut,
} from "react-scroll-motion";
import PropTypes from "prop-types";
import React from "react";
import ET from "../assets/Ethos.png";
import ET1 from "../assets/Ethos1.png";
import ET2 from "../assets/Ethos2.png";
import ET3 from "../assets/Ethos3.png";
import ET1M from "../assets/Ethos1Mobile.png";
import ET2M from "../assets/Ethos2Mobile.png";
import ET3M from "../assets/Ethos3Mobile.png";
import BG from "../assets/s6bg.png";

class Ethos extends React.Component {
  render() {
    return (
      <>
        <Animator animation={batch(Fade(), Sticky(50, 50))}>
          <div
            style={{
              display: this.props.width < 900 ? "block" : "none",
              width: this.props.width,
              height: this.props.height,
              backgroundImage: this.props.width < 900 ? `url("${BG}")` : ``,
              opacity: 0.9,
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            FadeIn(-1, 1),
            FadeOut(1, 0),
            Sticky(
              this.props.width < 900
                ? 100 - (235 / 2 / 375) * 100
                : ((972 + 447 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((109 + 105 / 2) / 834) * 100
                : ((331 + 199 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (235 / 375) * this.props.width
                  : (447 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (105 / 834) * this.props.height
                  : (199 / 1024) * this.props.height,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url("${ET}")`,
                backgroundPosition: this.props.width < 900 ? "right" : "left",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></div>
          </div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(500, -200),
            Sticky(
              this.props.width < 900
                ? (32 / this.props.width + 154 / 2 / 375) * 100
                : ((139 + 185 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((239 + 74 / 2) / 834) * 100
                : ((342 + 111 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (154 / 375) * this.props.width
                  : (185 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (74 / 834) * this.props.height
                  : (111 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${ET1M}")` : `url("${ET1}")`,
              backgroundPosition: this.props.width < 900 ? "left" : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(500, -200),
            Sticky(
              this.props.width < 900
                ? ((99 + 269 / 2) / 375) * 100
                : ((411 + 265 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((433 + 95 / 2) / 834) * 100
                : ((720 + 169 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (269 / 375) * this.props.width
                  : (265 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (95 / 834) * this.props.height
                  : (169 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${ET2M}")` : `url("${ET2}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
        <Animator
          animation={batch(
            Fade(),
            MoveIn(500, -200),
            Sticky(
              this.props.width < 900
                ? ((166 + 202 / 2) / 375) * 100
                : ((981 + 277 / 2) / 1440) * 100,
              this.props.width < 900
                ? ((648 + 116 / 2) / 834) * 100
                : ((540 + 169 / 2) / 1024) * 100
            )
          )}
        >
          <div
            style={{
              width:
                this.props.width < 900
                  ? (202 / 375) * this.props.width
                  : (277 / 1440) * this.props.width,
              height:
                this.props.width < 900
                  ? (116 / 834) * this.props.height
                  : (169 / 1024) * this.props.height,
              backgroundImage:
                this.props.width < 900 ? `url("${ET3M}")` : `url("${ET3}")`,
              backgroundPosition: this.props.width < 900 ? `center` : "left",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></div>
        </Animator>
      </>
    );
  }
}

Ethos.propTypes = {
  width: PropTypes.number,
};

Ethos.defaultProps = {
  width: 1440,
};

export default Ethos;
